import { countries } from 'countries-list';
import PropTypes from 'prop-types';
import 'rc-tabs/assets/index.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { forbiddenCountries } from '../../constants';
import { getCountryFilterParam } from '../../helpers/instanceHandler';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { setUserStartCountry, updateProducts } from '../../store/actions';
import { selectMetaSiteInfo } from '../../store/selectors/entities';
import { selectUserCountry } from '../../store/selectors/global';
import LanguageListItem from '../language-list-item';
import styles from './locale-tabs.module.scss';

const getAllCountries = (updatedData) =>
  Object.keys(countries)
    .map((item) => ({ ...countries[item.toUpperCase()], countryCode: item.toUpperCase() }))
    .filter((item) => !!item)
    .map((item) => {
      const { name } = updatedData?.find((x) => x.code === item.countryCode) || '';
      return { ...item, name: name || item.name };
    })

const LocaleTabs = ({ closeTo }) => {
  const dispatch = useDispatch();
  const userCountry = useSelector(selectUserCountry);
  const availableCountries = getCountryFilterParam();
  const stateSelector = createStructuredSelector({ metaSiteInfo: selectMetaSiteInfo });
  const { metaSiteInfo } = useSelector(stateSelector);

  const countriesFromAdminPanel = metaSiteInfo?.currentInstance?.countries;

  const countryList = availableCountries.length
    ? availableCountries
      .map((item) => ({ ...countries[item.toUpperCase()], countryCode: item.toUpperCase() }))
      .filter((item) => !!item)
    : getAllCountries(countriesFromAdminPanel);

  const allowedCountries = countryList?.filter(
    (item) => !forbiddenCountries.includes(item.countryCode.toLowerCase())
  );

  const onCountrySelect = (countryCode) => {
    dispatch(setUserStartCountry(countryCode));
    dispatch(updateProducts(countryCode));

    createNavigateTo(closeTo || pageLinks.home)();
  };

  return (
    <div className={styles.tabPaneContent}>
      <ul className={styles.languagesList}>
        {/* eslint-disable-next-line react/prop-types */}
        {allowedCountries.map(({ name, emoji, countryCode }) => (
          <LanguageListItem
            key={countryCode}
            name={name}
            flag={emoji}
            className={styles.languageListItem}
            onCountrySelect={() => onCountrySelect(countryCode)}
            isActive={userCountry === countryCode}
          />
        ))}
      </ul>
    </div>
  );
};

LocaleTabs.defaultProps = { closeTo: pageLinks.home };

LocaleTabs.propTypes = {
  closeTo: PropTypes.string,
};

export default LocaleTabs;
